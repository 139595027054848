* {
    --af-color-netdriven-blue: #496E9D;
    --af-color-footer-grey: #353535;
}

@font-face {
    font-family: 'Monaco_Regular';
    src: local('Monaco_Regular'), url(./asset/resource/Monaco_Regular.ttf) format('truetype');
}

body {
    margin: 0;
    color: #ffffff;
    background: #000000;
    font-size: 1.1rem;
    font-family: Monaco_Regular !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.main-wrapper {
    margin: 20px auto;
    width: 80vw;
    padding-top: 85px;
}

.app-bar-top {
    z-index: 1000 !important;
    position: fixed !important;
    width: 100vw;
    height: 85px;
    /*grid-area: header;*/
    background-color: #ffffff !important;
}

.text-size-l {
    font-size: 1.0rem;
    margin: 0;
}

a:link,
a:visited,
a:hover,
a:active {
    cursor: pointer;
    color: var(--af-color-netdriven-blue);
}


h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.4rem;
}

.navigation-header {
    /*height: 45px;*/
}

.af-cursor-pointer {
    cursor: pointer;
}

.text-size-m {
    font-size: 1.4rem !important;
}

.fullCenter {
    display: flex;
    justify-content: center;
    width: 100%;
}

.k-button {
    padding: 0 10px !important;
}

.k-badge-container {
    margin-right: 8px;
}

.k-card {
    color: #ffffff !important;
    background-color: #000000 !important;
}

.k-card-header {
    color: #ffffff !important;
    background-color: #000000 !important;
}

.App {
    text-align: center;
}

.App-logo {
    width: 200px;
    cursor: pointer;
    /*object-fit: none !important;*/
}

.profile-logo {
    width: 200px;
    object-fit: none !important;
}

.row-direction {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.column-direction {
    display: flex;
    flex-direction: column;
}


.justify-center {
    justify-content: center;
}

.justify-left {
    justify-content: left;
}

.action-buttons {
    align-items: flex-end !important;
    margin-top: 20px !important;
}

.card-box-default {
    width: 250px;
}

.k-button-text {
    color: black;
    font-size: 1.0rem;
}

.k-card-body, .k-card-header {
    padding: 0 !important;
}

.k-card, .k-card-body, .k-appbar-section, .k-appbar-top, .k-button-text {
    font-family: Monaco_Regular !important;
}

.k-card-title {
    font-weight: 800 !important;
}

.k-appbar-section {
    color: white !important;
}

.color-white {
    color: white !important;
}

div.k-appbar-section:nth-child(3) > button:nth-child(1) > span:nth-child(1),
div.k-appbar-section:nth-child(3) > button:nth-child(3) > span:nth-child(1) {
    color: white;
    font-weight: 400;
}

.k-card-title, .k-card-subtitle {
    font-family: Monaco_Regular !important;
    border-style: solid !important;
    border-left-color: var(--af-color-netdriven-blue) !important;
    border-left-width: 2px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0px !important;
    padding-left: 10px;
}

.k-card-list {
    flex-flow: wrap !important;
}

.k-card-deck {
    flex-flow: wrap !important;
}

.k-card-actions {
    background-color: white !important;
    justify-content: space-evenly !important;
}

.padding-left-right-l {
    padding: 0 5px !important;
}

.padding-left-l {
    padding-left: 40px;
}
.padding-bottom-l {
    padding-bottom: 20px;
}

.margin-left-right-l {
    margin: 5px;
}

.dev-icon {
    height: 50px;
    width: 50px;
}

.redhat-logo {
    width: 150px;
}

.laptop-logo {
    width: 500px;
}

/*Smartphones*/
/*#######################################*/
/*#######################################*/
/*#######################################*/
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 667px) {

    .main-wrapper {
        margin: 10px auto;
        width: 95vw;
    }

    .card-box-default {
        width: 170px;
    }

    .padding-left-l {
        padding-left: 0px;
    }

    .k-card-image {
        align-self: center !important;
        padding-bottom: 10px !important;
    }

}
